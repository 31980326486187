import ColumnedFormLayout from '../components/DynamicForm/ColumnedFormLayout';
import TabbedFormLayout from '../components/DynamicForm/TabbedFormLayout';
import { getMethods, searchFields, searchParams } from '../services/commonServices';
import authenticatedFetcher from '../services/fetcher';
import { validateConditions } from './formValidations';

const validLayoutTypes = [
	{ name: 'columns', component: ColumnedFormLayout },
	{ name: 'tabs', component: TabbedFormLayout }
];

const fetchTabData = (key, urlParams = {}) => {
	return new Promise(async (resolve) => {
		try {
			const data = await authenticatedFetcher({ path: `${key}`, urlParams });
			resolve(data);
		} catch (error) {
			resolve(null);
		}
	});
};

const buildCreateParams = (config) => {
	if (typeof config !== 'object') return {};
	return Object.entries(config).reduce(
		(acc, [key, value]) => {
			if (key.includes('fixed__')) {
				return {
					...acc,
					paramsFixed: {
						...acc.paramsFixed,
						[key.replace('fixed__', '')]: value
					}
				};
			}
			if (key.includes('reqOptions__')) {
				return {
					...acc,
					reqParams: {
						...acc.reqParams,
						[key.replace('reqOptions__', '')]: value
					}
				};
			}
			return {
				...acc,
				params: {
					...acc.params,
					[key]: value
				}
			};
		},
		{
			params: {},
			paramsFixed: {},
			reqParams: {}
		}
	);
};

const fetchRelatedCards = async (config, data, routes) => {
	const groupNames = Object.keys(config.relations);
	let groupsData = [];
	for (const gName of groupNames) {
		const g = config.relations[gName];
		let tabData = [],
			type = 'list';
		if ('custom_blocks' in g || 'custom_lists' in g) {
			if ('custom_blocks' in g) {
				type = 'form';
				for (const t of g.custom_blocks) {
					tabData.push(t);
				}
			}
			if ('custom_lists' in g) {
				type = 'listBlock';
				for (const t of g.custom_lists) {
					const dataUri = `/${t.endpoints.id}/`;
					const config = await fetchTabData(`${t.endpoints.list}`);
					const actions = getMethods(config.actions, routes);
					tabData.push({
						title: t.title,
						originConfig: t,
						config: {
							...config,
							actions
						},
						uri: dataUri,
						in_creation_form: 'in_creation_form' in t ? t.in_creation_form : false
					});
				}
			}
		} else
			for (const t of g.tabs) {
				const isTabValid = validateConditions(t.conditions, data);
				if (isTabValid) {
					const urlParams = searchParams(t, data);
					const {
						params: toCreateParams,
						paramsFixed: toCreateParamsFixed,
						reqParams
					} = buildCreateParams(t['params-creation']);
					const createFormData = searchParams(
						{ params: toCreateParams, 'params-fixed': toCreateParamsFixed },
						data
					);
					const reqParams2 = searchParams({ params: reqParams }, data);
					const dataUri = `/${t.endpoints.id}/`;
					const config = await fetchTabData(`${t.endpoints.list}`);
					const actions = getMethods(config.actions, routes);
					tabData.push({
						title: t.title,
						originConfig: t,
						config: {
							...config,
							actions,
							toCreateModal: { fieldWithParams: reqParams2, data: createFormData }
						},
						params: { ...urlParams },
						uri: dataUri,
						in_creation_form: 'in_creation_form' in t ? t.in_creation_form : false
					});
				}
			}
		groupsData.push({ title: g.title, tabs: tabData, type, id: gName });
	}
	return groupsData;
};

const getCustomFormConfig = (customConfig = {}, config = {}) => {
	const { fields = [], formfields = true } = customConfig ? customConfig : {};
	if (formfields) {
		return { ...config, fields: [...config.fields, ...fields] };
	} else {
		const tabs =
			Array.isArray(config?.layout?.tabs) &&
			config.layout.tabs.filter((item) => fields.some((field) => field.layout.id === item.id));
		return { ...config, layout: { ...config.layout, tabs }, fields };
	}
};

const getFilterFields = (fieldType) => {
	switch (fieldType) {
		case 'integer':
		case 'date':
		case 'datetime':
			return 'filterType';
		case 'file':
		case 'password':
			return 'unknow';
		default:
			return fieldType;
	}
};

const getOptions = (configField, data, fieldWithParams) => {
	const options = configField.options ? { ...configField.options } : {};
	const params = searchParams(options, data);
	const params_upload = searchParams(options, data, 'params_upload', 'params_upload_fixed');
	if (fieldWithParams && configField.name in fieldWithParams) {
		params['pks'] = Array.isArray(fieldWithParams[configField.name])
			? fieldWithParams[configField.name].join(',')
			: fieldWithParams[configField.name];
	}
	delete options.params;
	delete options['params-fixed'];
	return {
		...options,
		params,
		params_upload
	};
};

const getValue = (configField, data, loadDefaultData) => {
	return loadDefaultData ? configField.value : data[configField.name];
};

const getExtraSetting = (configField, data, type, filterMode = undefined) => {
	if (filterMode && type === 'filterType')
		return {
			valueInit: data[`${configField.name}__init`],
			valueEnd: data[`${configField.name}__finish`],
			originalType: configField.type
		};
	return {};
};

const getDefaultValue = (data, configField) => {
	if (configField.type === 'coordinates') {
		return data[`${configField.name}_x`] && data[`${configField.name}_y`]
			? data[`${configField.name}_x`] + '-' + data[`${configField.name}_y`]
			: '';
	}
	return data[`${configField.name}_data`] || data[configField.name];
};

export const getInputInfo = (configField, data, loadDefaultData, fieldWithParams, filterMode) => {
	const inputType = filterMode ? getFilterFields(configField.type) : configField.type;
	return {
		...configField,
		value: getValue(configField, data, loadDefaultData),
		type: inputType,
		options: getOptions(configField, data, fieldWithParams),
		relatedData: searchFields([[`${configField.name}__`, '']], data),
		defaultValue: getDefaultValue(data, configField),
		typeFilterData: getExtraSetting(configField, data, inputType, filterMode)
	};
};

const getTabsFromFormData = (config = {}, data = {}, options = {}) => {
	const {
		loadDefaultData = undefined,
		filterMode = undefined,
		fieldWithParams
	} = typeof options === 'object' ? options : {};
	if (!validLayoutTypes.some((layoutTypes) => layoutTypes.name === (config?.layout?.type || ''))) {
		throw TypeError(
			`Invalid layout type, it should be one of the following types (${validLayoutTypes.join(
				','
			)}) and received "${config.layout.type}" instead`
		);
	}

	const tabsMap = config.layout.tabs.reduce((tabs, tab) => {
		if (!validateConditions(tab.conditions, data)) return tabs;
		return { ...tabs, [tab.id]: tab };
	}, {});

	const fieldsGroupedByTabs = config.fields.reduce((group, configField) => {
		const tab = tabsMap[configField.layout.id];

		if (tab) {
			const field = getInputInfo(configField, data, loadDefaultData, fieldWithParams, filterMode);
			const fields = group[field.layout.id] || {
				id: field.layout.id,
				tab,
				body: [],
				footer: [],
				allFields: [],
				options: field.options
			};
			if ('conditions-print' in configField) {
				if ('conditionalFields' in fields)
					fields['conditionalFields'] = {
						...fields['conditionalFields'],
						[configField.name]: configField['conditions-print']
					};
				else fields['conditionalFields'] = { [configField.name]: configField['conditions-print'] };
			}
			fields[field.layout.position].push(field);
			fields.allFields.push(field);

			return {
				...group,
				[field.layout.id]: fields
			};
		}
		return group;
	}, {});
	return {
		defaultTab: config.layout.settings.defaultTab,
		tabs: Array.isArray(config.layout.tabs) ? config.layout.tabs.filter((item) => item.id in tabsMap) : [],
		tabsContent: Object.values(fieldsGroupedByTabs)
	};
};

const isArrayEmpty = (items) => {
	if (!Array.isArray(items)) {
		return true;
	}

	return items.length === 0;
};

const overwriteValue = (field = {}, value) => {
	let newValue = null;
	switch (field.type) {
		case 'text':
			newValue = value
			break;
		case 'select':
			newValue = field.options?.key_is_string ? value : +value
			break;
		default:
			newValue = field.value
	}
	return newValue || field.value
}
const overwriteFormData = (fields = [], overwriteData = {}) => {
	return Array.isArray(fields) ? fields.map((item) => ({ ...item, value: overwriteValue(item, overwriteData[item.name]) })) : []
}

export {
	getTabsFromFormData,
	isArrayEmpty,
	validLayoutTypes,
	validateConditions,
	getCustomFormConfig,
	fetchRelatedCards,
	buildCreateParams,
	overwriteFormData
};
