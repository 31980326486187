import React, { Fragment, memo, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Table } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import TableHeader from './TableHeader';
import TableHeaderTitles from './TableHeaderTitles';
import TableSearch from './TableSearch';
import TableFooterPag from './TableFooterPag';
import fetcher from '../services/fetcher';
import FilterSelect from './FilterSelect';
import TableRow from './TableRow';
import Create from './Create';
import TagsGroup from './TagsGroup';
import { toastError, toastSuccess } from '../utils/ToastConfig';
import { useTranslation } from 'react-i18next';
import { stringToStyleObj } from '../services/commonServices';
import { setMapConfig } from '../services/mapServices';
import LoadingSpinner from './LoadingSpinner';
import clsx from 'clsx';
// import AppContext from './List/ListContext';

const DynamicTable = (props) => {
	const {
		id,
		setFields,
		setKeywords,
		data = {},
		config = {},
		fetchData,
		page,
		onChangeFilters,
		withoutFilters,
		withBtnExcel,
		withoutPlusBtn,
		customName,
		addBtn,
		onDeleteAdvFilter = () => { },
		globalParams = {},
		withoutMargin,
		withoutHeader,
		withoutSearcher,
		loading
	} = props;
	const [openedItem, setOpenedItem] = useState();
	const [dataToCopy, setDataToCopy] = useState(null);
	const [checkAllInputs, setCheckAllInputs] = useState(false);
	const [filtersData, setFiltersData] = useState(config['__filters_from_params'] || {});
	const history = useHistory();

	const {
		header = {},
		footer = {},
		fields,
		name,
		search = {},
		search_fields: searchFilter = '',
		primary_key_field: pk,
		rows,
		actions,
		relations,
		filters,
		plus: plusConfig,
		export: exportConfig,
		advanced_search: advSearchConfig,
		toCreateModal = {}
	} = config;
	const [t] = useTranslation('app');

	const fieldsWhiteList = fields.map((field) => field.field);
	const { read, edit, write: writeMethod, delete: deleteConfig } = actions;

	// load the detail
	const fetchDataFromEdit = (pathTo, state = null) => {
		history.push(pathTo, state);
	};

	const handleTrashClick = (itemId) => (body) => {
		if (!deleteConfig) return;
		return fetcher({
			path: `${deleteConfig.uri}${itemId}`,
			method: deleteConfig.method,
			urlParams: body ? body : {}
		}).then((res) => {
			if ('error' in res && res.error !== 'no-content') {
				toastError(res.error);
				return false;
			}
			fetchData();
			toastSuccess(t('save'));
			return true;
		});
	};

	const handleMapClick = (pathTo) => (mapState) => {
		setMapConfig(mapState);
		fetchDataFromEdit(`${pathTo}/Map/`);
	};

	const handleCopyClick = (pkToCopy) => async () => {
		if (!pkToCopy) return setDataToCopy(null);
		if (read) {
			const dataFetched = await fetcher({ path: `${read.uri}${pkToCopy}` });
			dataFetched && setDataToCopy(dataFetched);
		}
	};

	const handleColumnCheck =
		({ endpoint, method, field }, pk) =>
			async ({ target: { checked } }) => {
				const dataFetched = await fetcher({ path: `${endpoint}${pk}/`, method, body: { [field]: checked } });
				if ('error' in dataFetched) return toastError(dataFetched.error);
				toastSuccess(t('save'));
			};
	const handleChangeInput =
		({ endpoint, method, field }, pk) =>
			async ({ target: { value } }) => {
				const dataFetched = await fetcher({ path: `${endpoint}${pk}/`, method, body: { [field]: value } });
				if ('error' in dataFetched) return toastError(dataFetched.error);
				toastSuccess(t('save'));
				fetchData();
			};

	const handleLupaClicked = (pathTo) => () => {
		fetchDataFromEdit(pathTo);
		localStorage.setItem('isLupaClicked', true);
	};
	const handleEditClicked = (pathTo) => () => {
		fetchDataFromEdit(pathTo);
		localStorage.setItem('isLupaClicked', false);
	};

	const handleOpenItem = (rowKey) => {
		setOpenedItem((prev) => (prev === rowKey ? null : rowKey));
	};

	const saveFiltersoptions = (dataToSave) => {
		onChangeFilters(dataToSave)
		setFiltersData(prev => ({...prev, ...dataToSave}))
	};

	const renderFilters = () =>
		withoutFilters
			? null
			: filters.map((filter, index) => {
				const cStyle = filter.style
					? stringToStyleObj(filter.style)
					: stringToStyleObj("max-width:175px");
				return (
					<FilterSelect
						key={`listFilter-${index}`}
						config={filter}
						dispatch={saveFiltersoptions}
						globalParams={globalParams}
						className="mx-sm-0 mx-md-1 mt-2 mt-md-0 mr-1 mr-md-0"
						style={cStyle}
						initialValue={filter.value}
						formData={filtersData}
					/>
				);
			});

	const renderBody = () => {
		return (
			data.items &&
			data.items.map((field, i) => (
				<TableRow
					key={`row-${i}-${field[pk]}`}
					primaryKey={pk}
					fieldsWhiteList={fieldsWhiteList}
					fieldData={field}
					id={id}
					edit={edit}
					read={read}
					relations={relations}
					rowsConfig={rows}
					openedItem={openedItem}
					setOpenedItem={handleOpenItem}
					checkAllInputs={checkAllInputs}
					page={page}
					headerFromMenu={header}
					name={name}
					fetchData={fetchData}
					plusConfig={plusConfig}
					handleLupaClicked={handleLupaClicked}
					handleEditClicked={handleEditClicked}
					handleTrashClick={handleTrashClick}
					handleMapClick={handleMapClick}
					handleCopyClick={handleCopyClick}
					handleColumnCheck={handleColumnCheck}
					handleChangeInput={handleChangeInput}
					fields={fields}
					globalConfig={config}
				/>
			))
		);
	};

	return (
		<>
			<Card className={`card-box ${withoutMargin ? '' : 'mb-5'} listado-personas`}>
				{!withoutHeader && (
					<TableHeader
						{...header}
						name={name}
						id={id}
						fetchData={fetchData}
						withBtnExcel={withBtnExcel}
						withoutPlusBtn={withoutPlusBtn}
						customName={customName}
						exportConfig={exportConfig}
						advSearchConfig={advSearchConfig}
						createModalProps={toCreateModal}
						actions={actions}
					/>
				)}
				<div
					className={clsx(`d-flex`, Array.isArray(data?.advanced_search_data) && data.advanced_search_data.length
						? 'justify-content-end'
						: 'justify-content-between flex-wrap', `px-4 py-3`)}>
					{Array.isArray(data?.advanced_search_data) && data.advanced_search_data.length ? (
						<TagsGroup
							items={data.advanced_search_data}
							labelField="label_tag"
							onDelete={onDeleteAdvFilter}
						/>
					) : (
						<Fragment>
							{search.display && !withoutSearcher && (
								<TableSearch
									{...search}
									fields={searchFilter || fields}
									setFields={setFields}
									setKeywords={setKeywords}
									paramId={config['__search_id']}
								/>
							)}
							{Array.isArray(filters) && <div className="d-flex flex-wrap">{renderFilters()}</div>}
						</Fragment>
					)}
				</div>
				<div className="divider" />
				<div className="table-responsive">
					<Table hover className="mb-0">
						<TableHeaderTitles
							name={name}
							id={id}
							fetchData={fetchData}
							page={page}
							setCheckAllInputs={setCheckAllInputs}
							fields={fields}
							addBtn={addBtn}
							extraConfig={rows}
						/>
						<tbody>{renderBody()}</tbody>
					</Table>
				</div>
				<LoadingSpinner show={loading} className="mx-auto my-2" />
				{dataToCopy ? (
					<Create
						toggle={handleCopyClick()}
						data={dataToCopy}
						id={writeMethod?.configUri || id}
						fetchData={fetchData}
						isCopy
					/>
				) : null}
				{footer.display && (
					<TableFooterPag
						{...search}
						visiblePages={4}
						lastPage={data.total_pages}
						totalItems={data.total_items}
						{...footer}
						page={page}
					/>
				)}
			</Card>
		</>
	);
};

DynamicTable.propTypes = {
	id: PropTypes.string.isRequired,
	fetchData: PropTypes.func.isRequired,
	config: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	onChangeFilters: PropTypes.func,
	withoutFilters: PropTypes.bool,
	withoutSearcher: PropTypes.bool,
	withoutMargin: PropTypes.bool,
	loading: PropTypes.bool
};

DynamicTable.defaultProps = {
	data: {},
	onChangeFilters: () => { }
};

export default memo(DynamicTable);
