import React from 'react';

const CustomSar = () => {
	return (
		<div style={{fontSize:"11px"}} className="my-4">
						<div className="card mb-4">
				<div style={{ backgroundColor: '#ddd819' }} className="card-header text-white">
					<h5>Buscador de Miembros Certificados con Seguimiento Abierto</h5>
				</div>
				<div className="card-body">
					<form>
						<div className="form-row">
							<div className="col-md-6 form-group">
								<label htmlFor="respostaAutomatica">Respuesta automática</label>
								<select className="form-control BasicInput" id="respostaAutomatica">
									<option value=""></option>
									<option value="1">Opción 1</option>
									<option value="2">Opción 2</option>
								</select>
							</div>
							<div className="col-md-6 form-group">
								<label htmlFor="numeroMembre">Número de miembro</label>
								<input type="text" className="form-control BasicInput" id="numeroMembre" />
							</div>
							<div className="col-md- form-group">
								<label htmlFor="nif2">NIF</label>
								<input type="text" className="form-control BasicInput" id="nif2" value="38557155r" />
							</div>
						</div>
						<div className="text-right">
							<button type="button" className="btn btn-secondary mr-1">
								&#10060;
							</button>
							<button style={{ cursor: 'pointer' }} type="button" className="btn btn-secondary mr-1">
								&#128215;
							</button>
							<button style={{ cursor: 'pointer' }} type="button" className="btn btn-secondary">
								&#x1F4DA;
							</button>
						</div>
					</form>
				</div>
			</div>
			{/* <!-- Sección 4 --> */}
			<div className="card mb-4 p-3">
				<div style={{ maxHeight: '250px' }} className="table-responsive mt-3">
					<table className="table table-bordered">
						<thead className="thead-light">
							<tr>
								<th>Revisión</th>
								<th>Apellido1</th>
								<th>Apellido2</th>
								<th>Nombre</th>
								<th>Usuario</th>
							</tr>
						</thead>
						<tbody style={{ overflowY: 'auto' }}>
							<tr>
								<td>04/08/23</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
							</tr>
							<tr>
								<td>04/08/23</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
							</tr>
							<tr>
								<td>04/08/23</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
							</tr>
							<tr>
								<td>04/08/23</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
							</tr>
							<tr>
								<td>04/08/23</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
							</tr>
							<tr>
								<td>04/08/23</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
							</tr>
							<tr>
								<td>04/08/23</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
							</tr>
							<tr>
								<td>04/08/23</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
							</tr>
							<tr>
								<td>04/08/23</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
							</tr>
							<tr>
								<td>04/08/23</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
								<td>Guest</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			{/* <!-- Sección 1 --> */}
		
			{/* <!-- Sección 2 --> */}
			<div className="card mb-4">
				<div
					style={{
						backgroundColor: '#1f487c',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}
					className="card-header text-white">
					<h5>Introducción Acciones Seguimiento Recertificación</h5>
					<button
						style={{ backgroundColor: '#dde6fa', cursor: 'pointer' }}
						type="button"
						className="btn btn-secondary">
						Informe
					</button>
				</div>
				<div className="card-body">
					<form>
						<div className="form-row">
							<div className="col-md-4 form-group">
								<label htmlFor="dataAccio">Fecha Acción</label>
								<input type="date" className="form-control BasicInput" id="dataAccio" value="2019-11-19" />
							</div>
							<div className="col-md-4 form-group">
								<label htmlFor="usuari">Usuario</label>
								<select className="form-control BasicInput" aria-label=".form-select-lg example">
									<option selected>Dani Marti</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>							</div>
							<div className="col-md-4 form-group">
								<label htmlFor="tipusDeContacte">Tipo de contacto</label>
								<select className="form-control BasicInput" aria-label=".form-select-lg example">
									<option selected>Email</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-4 form-group">
								<label htmlFor="respostaPredeterminada">Respuesta predeterminada</label>
								<select className="form-control BasicInput" aria-label=".form-select-lg example">
									<option value={""} selected></option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>							</div>
							<div className="col-md-8 form-group">
								<label htmlFor="observacions">Observaciones</label>
								<textarea className="form-control BasicInput" id="observacions" rows="3"></textarea>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-4 form-group">
								<label htmlFor="dataControl">Grupo Recertificación</label>
								<select className="form-control BasicInput" aria-label=".form-select-lg example">
									<option value="" selected></option>
									<option value="1">Diciembre 2020</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>
							</div>
							<div className="col-md-4 form-group">
								<label htmlFor="dataControl2">Fecha Control</label>
								<input type="date" className="form-control BasicInput" id="dataControl2" value="2023-05-31" />
							</div>
							<div className="col-md-4 form-group">
								<label htmlFor="recertificacions">Recertificaciones</label>
								<select className="form-control BasicInput" aria-label=".form-select-lg example">
									<option selected>Recertificaciones</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>
							</div>
						</div>
						<div className='form-row  justify-content-end'>
							<div className='col-md-4 '>
							<input type="text" className="form-control BasicInput" id="dataControl2" value="Horas completadas: 2" readOnly/>
							<input type="text" className="form-control BasicInput mt-2" id="dataControl2" value="Certificado: SEGUROS" readOnly/>

							</div>

						</div>
					</form>
					<div style={{ maxHeight: '250px' }} className="table-responsive mt-3">
						<table className="table table-bordered">
							<thead className="thead-light">
								<tr>
									<th>Entrada</th>
									<th>Usuario</th>
									<th>Vía comunicación</th>
									<th>Resp Predet</th>
									<th>Control</th>
									<th>Horas</th>
									<th>Certificación</th>
								</tr>
							</thead>
							<tbody style={{ overflowY: 'auto' }}>
								<tr>
									<td>04/08/23</td>
									<td>Guest</td>
									<td>Email</td>
									<td>Email</td>
									<td>19/08/23</td>
									<td>12</td>
									<td>SEGURC</td>
								</tr>
								<tr>
									<td>31/07/23</td>
									<td>Dani Martí</td>
									<td>Email</td>
									<td>Email</td>
									<td>15/08/23</td>
									<td>10</td>
									<td>LCCI</td>
								</tr>
								<tr>
									<td>31/07/23</td>
									<td>Dani Martí</td>
									<td>Email</td>
									<td>Email</td>
									<td>15/08/23</td>
									<td>10</td>
									<td>MiFID</td>
								</tr>
								<tr>
									<td>31/07/23</td>
									<td>Dani Martí</td>
									<td>Email</td>
									<td>Email</td>
									<td>15/08/23</td>
									<td>12</td>
									<td>SEGURC</td>
								</tr>
								<tr>
									<td>24/07/23</td>
									<td>Guest</td>
									<td>Email</td>
									<td>Comunica</td>
									<td>08/08/23</td>
									<td>10</td>
									<td>LCCI</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			{/* <!-- Sección 3 --> */}
			<div className="card mb-4">
				<div
					style={{
						backgroundColor: '#1f487c',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}
					className="card-header bg-primary text-white">
					<h5>Información Personal</h5>
					<button style={{ cursor: 'pointer' }} type="button" className="btn btn-secondary ">
						&#128209;
					</button>
				</div>
				<div className="card-body">
					<form>
						<div className="form-row">
							<div className="col-md-3 form-group">
								<input type="text" className="form-control BasicInput" id="nif" value="EFPA" />
							</div>
							<div className="col-md-3 form-group">
								<input type="text" className="form-control BasicInput" id="nombre" value="DANIEL MARTÍ" />
							</div>
							<div className="col-md-3 form-group">
								<input type="text" className="form-control BasicInput" id="apellido" value="GRANDE" />
							</div>
							<div className="col-md-3 form-group">
								<input type="text" className="form-control BasicInput" id="entidad" value="EFPA España" />
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-3 form-group">
								<label htmlFor="nif">NIF</label>
								<input type="text" className="form-control BasicInput" id="nif" value="38055155R" />
							</div>
							<div className="col-md-3 form-group">
								<label htmlFor="nombre">Tel Prof 1</label>
								<input type="text" className="form-control BasicInput" id="nombre" value="9414918492" />
							</div>
							<div className="col-md-3 form-group">
								<label htmlFor="apellido">Tel Cont. 1</label>
								<input type="text" className="form-control BasicInput" id="apellido" value="651475123" />
							</div>
							<div className="col-md-3 form-group">
								<label htmlFor="entidad">Correo</label>
								<input type="text" className="form-control BasicInput" id="entidad" value="dmarti@efpa.es" />
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-2 form-group">
								<label htmlFor="telefono1">Tel Prof 2</label>
								<input type="text" className="form-control BasicInput" id="telefono1" value="934121008" />
							</div>
							<div className="col-md-2 form-group">
								<label htmlFor="telefono2">Tel Cont. 2</label>
								<input type="text" className="form-control BasicInput" id="telefono2" value="678604260" />
							</div>
						</div>
						<div className="form-row justify-content-between">
							<div className="col-md-2 form-group">
								<label htmlFor="titulo">Num. Miembro</label>
								<input type="text" className="form-control BasicInput" id="titulo" value="333" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" value="" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" value="" />
							</div>
							<div className="col-md-3 form-group">
								<label htmlFor="fechaInicio">Certificado</label>
								<input
									type="text"
									className="form-control BasicInput"
									id="fechaInicio"
									value="European Financial Planner"
								/>
								<input type="text" className="form-control BasicInput mt-2" id="titulo" value="" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" value="" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" />
							</div>
							<div className="col-md-2 form-group">
								<label htmlFor="horas">Fecha</label>
								<input type="text" className="form-control BasicInput" id="horas" value="Diciembre 2020" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" value="" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" value="" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" />
							</div>
							<div className="col-md-1 form-group">
								<label style={{ fontSize: '9px' }} htmlFor="realizadas">
									Horas requeridas
								</label>
								<input type="text" className="form-control BasicInput" id="realizadas" value="32" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" value="" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" value="" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" />
							</div>
							<div className="col-md-1 form-group">
								<label style={{ fontSize: '9px' }} htmlFor="colegiado2">
									Horas Realizadas
								</label>
								<input type="text" className="form-control BasicInput" id="colegiado2" value="25" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" value="" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" value="" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" />
							</div>
							<div className="col-md-1 form-group">
								<label style={{ fontSize: '9px' }} htmlFor="colegiado2">
									Código ético web
								</label>
								<input type="text" className="form-control BasicInput" id="colegiado2" value="No" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" value="" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" value="" />
								<input type="text" className="form-control BasicInput mt-2" id="titulo" />
							</div>
						</div>
					</form>
				</div>
			</div>

		</div>
	);
};

export default CustomSar;
